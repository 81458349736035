import { AccessDenied } from "pages/AccessDenied";
import { ModuleClientMap } from "types";
import { ROLESINWORDS } from "constants/Role";
import { useSelector } from "react-redux";

type PermissionProps = {
  key?: string;
  href: string;
  children: JSX.Element;
  type?: string;
};

const PermissionsGate = ({ href, children, type }: PermissionProps) => {
  const authObj = useSelector((state: any) => state.auth);
  const modulesObj = useSelector((state: any) => state.modules);
  const { role } = authObj;
  const { modules } = modulesObj;
  if (role === ROLESINWORDS.CLIENT || role === ROLESINWORDS.EMPLOYEE) {
    const module = modules.find((module: ModuleClientMap) => {
      const splitHref = href.split("/").map(name => name.toLowerCase());
      if (splitHref.includes(module.Module.key.toLowerCase())) return module;
      return false;
    });
    if (!module || module.access) return children;
    return type === 'SIDEBAR' ? <></> : <AccessDenied />;
  }
  return children;
};
export default PermissionsGate;
