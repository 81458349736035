import { Navigate, useLocation } from 'react-router-dom';

type PrivateProps = {
  isAuthenticated: boolean;
  children: JSX.Element;
};

const PrivateRoute = ({ isAuthenticated, children }: PrivateProps) => {
  let location = useLocation();
  if (!isAuthenticated) return <Navigate to='/login' state={{ from: location }} />;
  return children;
};

export default PrivateRoute;
