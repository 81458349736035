import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import Logo from "assets/images/logo.png";
import { Navigation } from "constants/Navigation";
import { ROLES } from "constants/Role";
import { userLogoutAsync } from "redux/asyncActions/authAsyncActions";
import Spinner from "./Loader/Spinner";

const MobileNavBar = () => {
  const authObj = useSelector((state: any) => state.auth);
  const { role, user } = authObj;
  const dispatch = useDispatch();
  const location = useLocation();

  const [loader, setLoader] = useState(false);

  const logout = async () => {
    try {
      setLoader(true);
      setTimeout(() => {
        dispatch(userLogoutAsync());
        setLoader(false);
        localStorage.removeItem("location");
      }, 1000);
    } catch (err) {
      setLoader(false);
    }
  };

  return (
    <Popover className="fixed left-0 right-0 top-0 z-10 bg-brand print:hidden md:hidden">
      <div className="px-4 sm:px-6">
        <div className="flex items-center justify-between py-2">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <NavLink
              className="no-underline-all flex items-center"
              aria-label="Dashboard"
              to={`${ROLES[role]}`}
            >
              <img
                className="mx-auto h-16 w-auto"
                src={Logo}
                alt="Wext"
                loading="lazy"
              />
            </NavLink>
          </div>

          <div className="flex md:hidden">
            <div className="flex items-center space-x-4">
              <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 hover:rounded-full hover:bg-brand-700 hover:text-gray-800 focus:outline-none">
                <span className="sr-only">Open menu</span>
                <Bars3Icon
                  className="h-10 w-10 text-cyan-100"
                  aria-hidden="true"
                />
              </Popover.Button>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
          >
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-14 w-auto"
                      src={Logo}
                      alt="Wext"
                      loading="lazy"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-2">
                    {role &&
                      Navigation &&
                      Navigation[role].map((item: any) => (
                        <Popover.Button
                          key={item.href}
                          as={NavLink}
                          className={`${location.pathname === `${item.href}`
                              ? "text-brand"
                              : "text-black"
                            } no-underline-all group flex items-center px-2 py-2 text-base font-medium hover:text-brand`}
                          aria-current="page"
                          to={`${item.href}`}
                        >
                          {item.icon}
                          <span className="ml-3">{item.name}</span>
                        </Popover.Button>
                      ))}
                    <button
                      className="group relative flex w-full items-center justify-center rounded-full bg-brand p-3 font-medium text-white"
                      onClick={logout}
                    >
                      {loader ? (
                        <div>
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <Spinner />
                          </span>
                          <span className="tracking-wider">Logging out...</span>
                        </div>
                      ) : (
                        <div>
                          <span className="tracking-wider">Logout</span>
                        </div>
                      )}
                    </button>
                  </nav>
                </div>
                <div className="mt-4 p-2">
                  <div className="flex flex-col items-start rounded-lg bg-yellow-100 p-3 transition duration-150 ease-in-out">
                    <div>
                      <p className="text-sm font-medium text-yellow-700">
                        Message Limit:{" "}
                        <span className="font-bold">{user.dailyLimit}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
};

export default React.memo(MobileNavBar);
