export const ROLES: any = {
  SUPERADMIN: "super",
  ADMIN: "exec",
  CLIENT: "app",
  EMPLOYEE: "app",
};

export const ROLESINWORDS: any = {
  SUPERADMIN: "SUPERADMIN",
  ADMIN: "ADMIN",
  CLIENT: "CLIENT",
  EMPLOYEE: "EMPLOYEE",
};
