import { ROLES, ROLESINWORDS } from "constants/Role";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import Logo from "assets/images/logo.png";
import { Navigation } from "constants/Navigation";
import { userLogoutAsync } from "redux/asyncActions/authAsyncActions";
import Badge from "./Badge";
import Spinner from "./Loader/Spinner";
import PermissionsGate from "./PermissionGate";
import Slogan from "./Slogan";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const authObj = useSelector((state: any) => state.auth);
  const { role, user } = authObj;

  const [loader, setLoader] = useState(false);

  const logout = async () => {
    try {
      setLoader(true);
      setTimeout(() => {
        dispatch(userLogoutAsync());
        setLoader(false);
        localStorage.removeItem("location");
      }, 1000);
    } catch (err) {
      setLoader(false);
    }
  };

  return (
    <div className="border-gray hidden h-screen border-r bg-gray-50 print:hidden md:flex md:flex-shrink-0">
      <div className="flex w-64 flex-col">
        <div className="flex h-full flex-grow flex-col">
          <div className="flex flex-shrink-0 items-center py-2 pl-1 pr-3">
            <NavLink
              className="no-underline-all flex items-center space-x-2 px-2"
              aria-label="Dashboard"
              to={`${ROLES[role]}/dashboard`}
            >
              <img
                className="h-12 w-auto"
                src={Logo}
                alt="Wext"
                loading="lazy"
              />
            </NavLink>
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-3xl font-bold text-brand">Wext</h1>
              <Slogan titleSize="text-xxs" />
            </div>
          </div>
          <div className="mt-3 flex flex-1 flex-col overflow-y-auto">
            <nav id="sidebar" className="flex-1">
              <div className="space-y-1 pb-6 text-gray-400">
                {role &&
                  Navigation &&
                  Navigation[role].map((item: any) => (
                    <PermissionsGate key={item.href} href={item.href} type="SIDEBAR">
                      <NavLink
                        key={item.href}
                        to={item.href}
                        className={`${item.activeUrl.includes(location.pathname)
                          ? "bg-brand-50 font-semibold text-brand-800"
                          : "text-black"
                          }  no-underline-all focus:shadow-outline group flex transform items-center justify-between rounded-lg px-4 py-2 text-sm font-medium transition duration-200 ease-in-out hover:scale-95 hover:bg-brand-50 hover:text-brand-800`}
                        aria-current="page"
                      >
                        <span className="flex items-center">
                          {item.icon}
                          <span className="ml-2">{item.name}</span>
                        </span>
                        {item?.beta && <Badge name="Beta" />}
                      </NavLink>
                    </PermissionsGate>
                  ))}
              </div>
            </nav>
          </div>
          {role !== ROLESINWORDS.SUPERADMIN && (
            <div className="px-4 py-2">
              <div className="flex flex-col items-start rounded-lg bg-yellow-100 p-3 transition duration-150 ease-in-out">
                <p className="text-sm font-medium text-yellow-700">
                  Daily Message Limit:{" "}
                  <span className="font-bold">{user.dailyLimit}</span>
                </p>
              </div>
            </div>
          )}
          <div className="border-gray border-t">
            <div className="no-underline-all hover:bg-grayest group block w-full flex-shrink-0 px-4 py-2">
              <button
                className="group relative flex w-full items-center justify-center rounded-md bg-brand-800 p-2 font-medium text-white shadow-sm hover:bg-brand-900"
                onClick={logout}
              >
                {loader ? (
                  <div>
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
                      <Spinner />
                    </span>
                    <span>Logging out...</span>
                  </div>
                ) : (
                  <span>Logout</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
