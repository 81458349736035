import { defaultTemplateParams } from "constants/DefaultValues";
import url from "helper/url";
import { cloneDeep } from "lodash";
import { Template } from "types";

export const Templates = {
  checkParamater: (t: Template) => {
    const count = cloneDeep(defaultTemplateParams);
    const expression = new RegExp(/{{[\d]{1,2}}}/gm, "g");
    for (const i of t.components) {
      if (i.type === "HEADER") {
        switch (i.format) {
          case "IMAGE":
            count.header[0] = {
              type: "image",
              image: { link: "" },
              media: "",
              i,
            };
            break;
          case "VIDEO":
            count.header[0] = {
              type: "video",
              video: { link: "" },
              media: "",
              i,
            };
            break;
          case "DOCUMENT":
            count.header[0] = {
              type: "document",
              document: { link: "" },
              media: "",
              i,
            };
            break;
          case "TEXT":
            const length = i?.text?.match(expression)?.length as number;
            for (let i = 0; i < length; i++) {
              const isFound = count.header.find((c: any) => c && c.index === i);
              if (!isFound) {
                count.header[i] = { type: "text", text: "", i };
              }
            }
            break;
          default:
            break;
        }
      }
      if (i.type === "BODY") {
        const length = i?.text?.match(expression)?.length as number;
        for (let i = 0; i < length; i++) {
          const isFound = count.body.find((c: any) => c && c.index === i);
          if (!isFound) {
            count.body[i] = { type: "text", text: "", i };
          }
        }
      }
      if (i.type === "FOOTER") {
        const length = i?.text?.match(expression)?.length as number;
        for (let i = 0; i < length; i++) {
          const isFound = count.footer.find((c: any) => c && c.index === i);
          if (!isFound) {
            count.footer[i] = { type: "text", text: "", i };
          }
        }
      }
    }
    count.totalCount =
      Number(count.header.length) +
      Number(count.body.length) +
      Number(count.footer.length);
    return count;
  },
  setParameterValue: (value: any, template: Template, count: any) => {
    const cloneTemplate = cloneDeep(template);
    for (const component of cloneTemplate.components) {
      if (
        (component.type === "HEADER" || component.type === "header") &&
        count.header.length > 0
      ) {
        switch (component.format) {
          case "image":
          case "IMAGE":
            component.image = { link: `${value?.header[0]?.media}` };
            break;
          case "video":
          case "VIDEO":
            component.video = { link: `${value?.header[0]?.media}` };
            break;
          case "document":
          case "DOCUMENT":
            component.document = { link: `${value?.header[0]?.media}` };
            break;
          case "text":
          case "TEXT":
            for (const [index, j] of value.header.entries()) {
              const i = index + 1;
              if (j.text.length > 0) {
                component.text = component?.text?.replace(
                  `{{${i}}}`,
                  value.header[index].text
                );
              }
            }
            break;
          default:
            break;
        }
      }
      if (
        (component.type === "BODY" || component.type === "body") &&
        count.body.length > 0
      ) {
        if (value && value.body) {
          for (const [index, j] of value.body.entries()) {
            const i = index + 1;
            if (j.text.length > 0) {
              component.text = component?.text?.replace(
                `{{${i}}}`,
                value.body[index].text
              );
            }
          }
        }
      }
      if (
        (component.type === "FOOTER" || component.type === "footer") &&
        count.footer.length > 0
      ) {
        if (value && value.footer) {
          for (const [index, j] of value.footer.entries()) {
            const i = index + 1;
            if (j.text.length > 0) {
              component.text = value?.text?.replace(
                `{{${i}}}`,
                value.body[index].text
              );
            }
          }
        }
      }
    }
    return cloneTemplate;
  },
  setComponentParameter: (formData: any, data: any) => {
    const header: any = { type: "header", parameters: [] };
    const body: any = { type: "body", parameters: [] };
    const footer: any = { type: "footer", parameters: [] };
    const components = [];
    if (data.header.length > 0) {
      header.parameters = data.header.map((h: any, index: any) => {
        const body: any = { type: h.type };
        if (h.type === "text") {
          body.text = formData.header[index].text;
        } else {
          body[h.type] = {
            link: formData.header[index].media,
          };
          if (h.type === "document") {
            body[h.type] = {
              link: formData.header[index].media,
              filename: url.getFilename(`${formData.header[index].media}`),
            };
          }
        }
        return body;
      });
      components.push(header);
    }
    if (data.body.length > 0) {
      body.parameters = formData.body.map((h: any) => ({
        type: "text",
        text: h.text,
      }));
      components.push(body);
    }
    if (data.footer.length > 0) {
      footer.parameters = formData.footer.map((h: any) => ({
        type: "text",
        text: h.text,
      }));
      components.push(footer);
    }
    return components;
  },
};
