import { useEffect, useState } from "react";

import { Navigation } from "constants/Navigation";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AuthHeader from "./components/Header/AuthHeader";
import MobileNavBar from "./components/MobileNavBar";
import Sidebar from "./components/Sidebar";
import { Router } from "./router/Router";

/**
 * Top level application router
 *
 * @returns {Component}
 */
export default function AuthBase(): React.ReactElement {
  const location = useLocation();
  const authObj = useSelector((state: any) => state.auth);
  const { innerWidth: width } = window;
  const [mobileNavBar, setMobileNavBar] = useState(false);
  const { role } = authObj;
  const [isMessageWindow, setIsMessageWindow] = useState<boolean>(false);

  useEffect(() => {
    const isMobileVersion = width < 768;
    setMobileNavBar(isMobileVersion);
  }, [width]);

  useEffect(() => {
    const checkMessageWindow = (location.pathname === Navigation[role][1].href || location.pathname === Navigation[role][2].href) && (role === "CLIENT" || role === "EMPLOYEE");
    setIsMessageWindow(checkMessageWindow);
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [location.pathname, role]);

  return (
    <div>
      {mobileNavBar && <MobileNavBar />}
      <div className="wext-auth-base mt-20 flex overflow-hidden print:h-auto sm:mt-0 sm:h-screen">
        {!mobileNavBar && <Sidebar />}
        <div className="flex w-0 flex-1 flex-col overflow-hidden">
          <main className="relative flex-1 overflow-y-auto overflow-x-hidden">
            {!isMessageWindow && <AuthHeader />}
            <Router />
          </main>
        </div>
      </div>
    </div>
  );
}
