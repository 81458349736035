/**
 * Application wide configuration.
 */
import { BASEURL } from "./baseUrl";
const config = {
  env: process.env.NODE_ENV,
  endpoints: {
    auth: {
      login: `${BASEURL}/auth/login`,
      register: `${BASEURL}/auth/register`,
      logout: `${BASEURL}/auth/logout`,
      refreshToken: `${BASEURL}/auth/refresh-token`,
      forgotPassword: `${BASEURL}/auth/forgot-password`,
      changePassword: `${BASEURL}/auth/change-password`,
      verifyPassword: `${BASEURL}/auth/verify-password`,
      storeError: `${BASEURL}/auth/error`,
    },
    business: {
      getBusiness: `${BASEURL}/business`,
      createOrUpdate: `${BASEURL}/business/create`,
      dailyLimit: `${BASEURL}/business/limit`,
    },
    chat: {
      getRecipients: `${BASEURL}/chat/receipients`,
      sendMessage: `${BASEURL}/chat/messages`,
      sendBulkMessage: `${BASEURL}/chat/bulk-messages`,
      getAllMessages: `${BASEURL}/chat/messages`,
      readMessages: `${BASEURL}/chat/read`,
      sendBulkMessageOnLabels: `${BASEURL}/chat/bulk-messages/labels`,
    },
    campaigns: {
      getCampaigns: `${BASEURL}/campaigns`,
      create: `${BASEURL}/campaigns/create`,
      show: `${BASEURL}/campaigns/show`,
      messages: `${BASEURL}/campaigns/messages`,
      export: `${BASEURL}/campaigns/export`,
    },
    client: {
      allClients: `${BASEURL}/client`,
      getById: `${BASEURL}/client`,
      remove: `${BASEURL}/client/remove`,
      getTransactions: `${BASEURL}/client/transactions`,
      getUsers: `${BASEURL}/client/users/all`,
      createOrUpdate: `${BASEURL}/client/user/createOrUpdate`,
      toggleAccount: `${BASEURL}/client/user/toggleAccount`,
    },
    contacts: {
      allContacts: `${BASEURL}/receipient`,
      create: `${BASEURL}/receipient/create`,
      update: `${BASEURL}/receipient/update`,
      delete: `${BASEURL}/receipient`,
    },
    credits: {
      createOrUpdate: `${BASEURL}/credit/create`,
    },
    dashboard: {
      stats: `${BASEURL}/dashboard/stats`,
      exportTransaction: `${BASEURL}/dashboard/transactions/export`,
      adminStats: `${BASEURL}/dashboard/admin/stats`,
    },
    errors: {
      getSystemLogs: `${BASEURL}/logs`,
      getMessageLogs: `${BASEURL}/logs/message`,
    },
    tags: {
      getTags: `${BASEURL}/tags`,
    },
    template: {
      getTemplates: `${BASEURL}/template`,
    },
    user: {
      profile: `${BASEURL}/user/profile`,
    },
    media: {
      get: `${BASEURL}/media`,
      create: `${BASEURL}/media`,
      delete: `${BASEURL}/media`,
    },
    bulk: {
      recipient: `${BASEURL}/bulk/receipient`,
    },
    pricing: {
      createOrUpdate: `${BASEURL}/pricing/create`,
    },
    approval: {
      get: `${BASEURL}/approval`,
    },
    module: {
      getOrUpdate: `${BASEURL}/module`,
    },
    collection: {
      get: `${BASEURL}/collection/get`,
      create: `${BASEURL}/collection/create`,
      update: `${BASEURL}/collection/update`,
    },
    order: {
      get: `${BASEURL}/order/get`,
    },
    support: {
      get: `${BASEURL}/support/get`,
      create: `${BASEURL}/support/create`,
      update: `${BASEURL}/support/update`,
    },
    deduction: {
      getOrUpdate: `${BASEURL}/deduction`,
    },
  },
  role: {
    ADMIN: `ADMIN`,
  },
};

export default config;
