import { Navigate, useLocation } from "react-router";

import { ROLES } from "constants/Role";
import { useSelector } from "react-redux";

type PublicProps = {
  isAuthenticated: boolean;
  children: JSX.Element;
};

const PublicRoute = ({ isAuthenticated, children }: PublicProps) => {
  const authObj = useSelector((state: any) => state.auth);
  const { role } = authObj;
  const location = useLocation();

  const url = ROLES[role];
  if (isAuthenticated) return <Navigate to={`/${url}`} state={{ from: location }} />;
  return children;
};
export default PublicRoute;
