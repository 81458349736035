import { useEffect, useState } from "react";

import DOCS from "assets/images/docs.png";
import FILE from "assets/images/file.png";
import PDF from "assets/images/pdf.png";
import XLS from "assets/images/xls.png";
import url from "helper/url";

const FileIcon = ({ path }: { path: string; }): JSX.Element => {
  const [icon, setIcon] = useState<any>();

  useEffect(() => {
    const getExtension = url.getExtension(path);
    const lowercase = getExtension.toLowerCase();
    switch (lowercase) {
      case "pdf":
        setIcon(PDF);
        break;
      case "doc":
      case "docx":
        setIcon(DOCS);
        break;
      case "xls":
      case "xlsx":
        setIcon(XLS);
        break;
      default:
        setIcon(FILE);
        break;
    }
  }, [path]);
  return (
    <div>
      <img src={icon} className="h-10 w-10" alt={path} />
    </div>
  );
};

export default FileIcon;
