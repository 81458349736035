import Logo from "./Logo";
const LazyLoader = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center space-y-1 bg-white">
      <Logo />
      <h1 className="text pb-8 text-3xl font-bold text-brand">Wext</h1>
      <div className="flex-column relative flex items-center space-x-2">
        <span className="relative flex h-3 w-3">
          <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-brand-400 opacity-75"></span>
          <span className="relative inline-flex h-3 w-3 rounded-full bg-brand"></span>
        </span>
        <span className="relative flex h-3 w-3">
          <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-secondary-400 opacity-75"></span>
          <span className="relative inline-flex h-3 w-3 rounded-full bg-secondary"></span>
        </span>
        <span className="relative flex h-3 w-3">
          <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-secondary-400 opacity-75"></span>
          <span className="relative inline-flex h-3 w-3 rounded-full bg-secondary-500"></span>
        </span>
      </div>
      <h5 className="font-semibold text-brand">Please Wait....</h5>
    </div>
  );
};
export default LazyLoader;
