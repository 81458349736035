const Slogan = ({ titleSize }: { titleSize?: string; }) => {
  return (
    <div className="flex space-x-2">
      <h4
        className={`text-secondary-900 ${titleSize ?? ""} font-semibold`}
      >
        By AInocular
      </h4>
    </div>
  );
};
export default Slogan;
