import { Dialog, Transition } from "@headlessui/react";

import { Fragment } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const Modal = ({
  size,
  show,
  children,
  onCloseModal,
}: {
  size?: string;
  show: boolean;
  children: any;
  onCloseModal: Function;
}) => {
  const closeModal = () => {
    onCloseModal();
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-120" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity bg-opacity-75 animate-in fade-in"></div>
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`inline-block w-full ${
                  size ? size : "max-w-md"
                } my-8 transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all`}
              >
                <div className="absolute right-3 top-3 z-120" tabIndex={-1}>
                  <button
                    onClick={closeModal}
                    type="button"
                    className="rounded-full bg-brand-200/30 p-1 outline-none"
                  >
                    <XMarkIcon className="h-6 w-6 text-brand-300" />
                  </button>
                </div>
                {children}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default Modal;
