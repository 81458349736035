export const VERIFY_TOKEN_STARTED = "VERIFY_TOKEN_STARTED";
export const VERIFY_TOKEN_END = "VERIFY_TOKEN_END";

export const USER_LOGIN_STARTED = "USER_LOGIN_STARTED";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const USER_LOGOUT = "USER_LOGOUT";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_BALANCE = "UPDATE_USER_BALANCE";

export const BULK_MSG_CONTACT = "BULK_MSG_CONTACT";

export const GET_MODULE = "GET_MODULE";
