import * as axios from "axios";

import { userLogout, verifyUserSuccess } from "../redux/actions/authActions";

import config from "config";
import store from "../store";

let token = "";
function select(state: any) {
  const AUTH = state.auth;
  if (AUTH.token !== "") {
    return AUTH.token;
  }
}
function listener() {
  token = select(store.getState());
  return token;
}

const http = axios.default.create();
http.defaults.timeout = 600000;
http.defaults.withCredentials = true;
http.defaults.headers.common = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};
http.interceptors.request.use(
  (config: any) => {
    store.subscribe(listener);
    if (token) {
      if (config.headers === null) {
        config.headers = {};
      }
      config.headers.authorization = token;
    }
    // Do something before request is sent
    return config;
  },
  (error: any) => {
    // Do something with request error
    return Promise.reject(error);
  }
);
/**
 * intercepts any response and checks the response from our api for a 401 status in the response. ie. the token has now expired and is no longer valid, or no valid token was sent.
If such a status exists then we log out the user and clear the profile from redux state.
 */
http.interceptors.response.use(
  (res: any) => res,
  (err: any) => {
    const originalRequest = err.config;
    if (err.response.status === 401 || err.response.data.status === 401) {
      if (err.response.data.message === "SESSION_EXPIRED") {
        window.location.href = "/login";
        return;
      }
      if (
        err.response.data.message === "ACCESS_SESSION_EXPIRED" &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const url = config.endpoints.auth.refreshToken;
        return http.post(url).then((res) => {
          if (res.status === 200) {
            store.dispatch(verifyUserSuccess(res.data));
            return http(originalRequest);
          } else {
            store.dispatch(userLogout());
            return null;
          }
        });
      }
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
);

export default http;
