const NumberUtil = {
  format: (amount: number) => {
    return new Intl.NumberFormat("en-IN", {
      maximumSignificantDigits: 12,
    }).format(amount ?? 0);
  },
  formatCurrency: (amount: number) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount ?? 0);
  },
};

export default NumberUtil;
