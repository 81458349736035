export const Url = {
  addHttp: (url: string) => {
    if (!/^(?:f|ht)tps?:\/\//.test(url)) {
      url = "http://" + url;
    }
    return url;
  },
  getExtension: (url: string) => {
    if (url) {
      return url.substring(url.lastIndexOf(".") + 1);
    }
    return "";
  },
  getFilename: (url: string) => {
    if (url) {
      return url.substring(url.lastIndexOf("/") + 1);
    }
    return "";
  },
  isLocalhost: (url: string) => {
    return url.includes("localhost") || url.includes("127.0.0.1");
  },
};
export default Url;
