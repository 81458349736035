import { SocketContext, socket } from "context/socket";
import { useDispatch, useSelector } from "react-redux";

import ScrollToTop from "components/ScrollToTop";
import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import AuthBase from "./AuthBase";
import { verifyTokenAsync } from "./redux/asyncActions/authAsyncActions";
import { Router } from "./router/Router";

/**
 * Top level application router
 *
 * @returns {Component}
 */
export default function App(): React.ReactElement {
  const dispatch = useDispatch();
  const authObj = useSelector((state: any) => state.auth);
  const { isAuthenticated } = authObj;

  useEffect(() => {
    dispatch(verifyTokenAsync());
  }, [dispatch]);

  useEffect(() => {
    if (window) {
      window.addEventListener("load", () => {
        Notification.requestPermission();
      });
    }
    return () => {
      window.removeEventListener('load', () => { });
    };

  }, []);

  return (
    <HelmetProvider>
      <SocketContext.Provider value={socket}>
        <ScrollToTop>
          {isAuthenticated === true ? <AuthBase /> : <Router />}
          <Toaster containerClassName="font-sans font-medium" />
        </ScrollToTop>
      </SocketContext.Provider>
    </HelmetProvider>
  );
}
