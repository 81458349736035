import { Template, TemplateComponents } from "types";

import Badge from "components/Badge";
import DOMPurify from "dompurify";

const Preview = ({ data }: { data: Template; }) => {
  const getBody = (body: TemplateComponents[]) => {
    const index = body.findIndex(
      (component: TemplateComponents) => component.type === "BODY"
    );
    return (
      <div
        className="body overflow-hidden text-ellipsis"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(body[index].text as string),
        }}
      />
    );
  };

  return (
    <div className="relative w-auto rounded-md border border-gray-200 bg-white p-2 text-sm text-gray-700">
      <div className="flex flex-col">
        <div className="flex flex-col space-y-2">
          <div className="flex items-center justify-between">
            <h1 className="font-semibold text-brand">
              {data.name}
              <span className="ml-2 uppercase text-gray-400">
                ({data.language})
              </span>
            </h1>
            <Badge name={data.category} />
          </div>
          {getBody(data.components)}
        </div>
      </div>
    </div>
  );
};
export default Preview;
