import { GET_MODULE } from "redux/actions/actionTypes";

// define initial state of auth reducer
const initialState = {
  modules: []
};

const modules = (state = initialState, action: any) => {
  switch (action.type) {
    // verify token - started
    case GET_MODULE: {
      const { modules } = action.payload;
      return {
        ...state,
        modules
      };
    }
    default:
      return state;
  }
};

export default modules;