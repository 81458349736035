import {
  updateUser,
  updateUserBalance,
  userLoginFailure,
  userLoginStarted,
  userLogout,
  verifyTokenEnd,
  verifyTokenStarted,
  verifyUserSuccess,
} from "../actions/authActions";

import { AxiosError } from "axios";
import config from "config";
import http from "utils/http";

// handle verify token
export const verifyTokenAsync =
  (silentAuth = false) =>
    async (dispatch: any) => {
      dispatch(verifyTokenStarted(silentAuth));
      const url = config.endpoints.auth.refreshToken;
      try {
        const { data, status } = await http.post(url);
        if (status === 204) {
          dispatch(verifyTokenEnd());
        } else if (status === 200) {
          dispatch(verifyUserSuccess(data));
        } else {
          dispatch(userLogout());
        }
        return;
      } catch (error) {
        const err = error as AxiosError;
        dispatch(verifyTokenEnd());
        if (err?.response && [401, 403].includes(err.response.status))
          dispatch(userLogout());
        return;
      }
    };

// handle user login
export const userLoginAsync = (loginData: any) => async (dispatch: any) => {
  dispatch(userLoginStarted());
  const url = config.endpoints.auth.login;
  try {
    const { data }: any = await http.post(url, loginData);
    dispatch(verifyUserSuccess(data));
  } catch (error) {
    const err = error as AxiosError;
    const { response: { data = {} } = {} } = err;
    dispatch(userLoginFailure(data?.message));
    return;
  }
};

// handle user logout
export const userLogoutAsync = () => async (dispatch: any) => {
  const url = config.endpoints.auth.logout;
  try {
    const { status }: any = await http.post(url);
    if (status === 200) {
      dispatch(userLogout());
    }
  } catch (err) {
    return;
  }
};

export const updateUserAsync = (data: any) => async (dispatch: any) => {
  dispatch(updateUser(data));
};

export const updateUserBalanceAsync = (data: any) => async (dispatch: any) => {
  dispatch(updateUserBalance(data));
};
