import WEXTLogo from 'assets/images/logo.png';
const Logo = () => {
  return (
    <div>
      <img
        src={WEXTLogo}
        loading='lazy'
        className='w-12 h-12'
        alt='Wext'
        width='64'
        height='64'
      />
    </div>
  );
};
export default Logo;
