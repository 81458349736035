import { BULK_MSG_CONTACT } from "redux/actions/actionTypes";

// define initial state of bulk message reducer
const initialState = {
  users: [],
};

const bulkMessage = (state = initialState, action: any) => {
  switch (action.type) {
    // set bulk message users
    case BULK_MSG_CONTACT: {
      const { users } = action.payload;
      return {
        ...state,
        users,
      };
    }
    default:
      return state;
  }
};

export default bulkMessage;
