import { useEffect, useState } from "react";

import BulkChat from "../BulkChat";
import Coin from "assets/images/coin.png";
import { NavLink } from "react-router-dom";
import NumberUtil from "helper/numberFormat";
import { ROLES } from "constants/Role";
import User from "assets/images/user.webp";
import { useSelector } from "react-redux";

const AuthHeader = (): JSX.Element => {
  const authObj = useSelector((state: any) => state.auth);
  const { user: stateUser = {}, role } = authObj;
  const [user, setUser] = useState({ name: "", avatar_url: "" });
  const BASEURL = ROLES[role];
  const [openBulk, setOpenBulk] = useState<boolean>(false);

  useEffect(() => {
    setUser(stateUser);
  }, [user, stateUser]);

  return (
    <div className="z-100 hidden items-center justify-between border-b border-gray-200 bg-white p-4 sm:sticky sm:top-0 sm:flex md:py-3 md:px-6 lg:px-8">
      <span className="flex flex-row">
        <span className="text-lg font-medium text-gray-600 md:text-sm">
          Welcome{" "}
          <span className="font-semibold capitalize text-brand-600">
            {user.name}!
          </span>{" "}
        </span>
      </span>
      <div className="flex items-center space-x-3">
        <div className="flex items-center space-x-4 text-sm focus:outline-none">
          {(role === "CLIENT" || role === "EMPLOYEE") && (
            <div
              className="flex items-center justify-end space-x-1"
              aria-label="Balance"
            >
              <img src={Coin} alt="Credits" className="h-6 w-6" />
              <span className="font-bold">
                {NumberUtil.format(stateUser?.balance)}
              </span>
            </div>
          )}
          <NavLink
            to={`/${BASEURL}/settings`}
            className="flex items-center space-x-2 text-sm focus:outline-none"
          >
            <img
              className="h-8 w-8 rounded-full p-1 ring-2 ring-gray-300"
              src={user.avatar_url || User}
              alt={user?.name}
              loading="lazy"
            />
            <span className="text-xl font-medium capitalize text-black md:text-sm">
              {user?.name}
            </span>
          </NavLink>
        </div>
      </div>
      {openBulk && (
        <BulkChat show={openBulk} closeModal={() => setOpenBulk(false)} />
      )}
    </div>
  );
};
export default AuthHeader;
