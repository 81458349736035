import {
  defaultTemplate,
  defaultTemplateParams,
} from "constants/DefaultValues";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { OptionsData, Template, TemplateParameter } from "types";

import { RadioGroup } from "@headlessui/react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import Parameters from "components/Chat/Templates/Parameter";
import Preview from "components/Chat/Templates/Preview";
import Spinner from "components/Loader/Spinner";
import Modal from "components/Modal";
import config from "config";
import { Templates as TemplateHelper } from "helper/template";
import toast from "react-hot-toast";
import { bulkMsgUsersAsync } from "redux/asyncActions/bulkMsgAsyncActions";
import http from "utils/http";

const Input = (props: any) => (
  <components.Input
    {...props}
    inputClassName="outline-none border-none shadow-none focus:ring-transparent"
  />
);

const BulkChat = ({
  show,
  closeModal,
}: {
  show: boolean;
  closeModal: Function;
}) => {
  const dispatch = useDispatch();
  const bulkMessageObj = useSelector((state: any) => state.bulkMessage);
  const { users } = bulkMessageObj;

  const [recipients, setRecipients] = useState<OptionsData[]>([]);
  const [templates, setTemplates] = useState<Template[] | []>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [selectedRecipients, setSelectedRecipients] = useState<OptionsData[]>(
    []
  );
  const [selectedTemplate, setSelectedTemplate] =
    useState<Template>(defaultTemplate);
  const [templateParameter, setTemplateParameter] = useState<TemplateParameter>(
    defaultTemplateParams
  );
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      await getRecipients();
      await getTemplates();
    })();
  }, []);

  useEffect(() => {
    const selected =
      users && users.length > 0
        ? users.map((res: any) => ({
          label: `${res.name} (+${res.phone})`,
          value: res.phone,
        }))
        : [];
    setSelectedRecipients(selected);
  }, [users]);

  const getRecipients = async () => {
    const url = `${config.endpoints.chat.getRecipients}`;
    try {
      const { data }: any = await http.get(url);
      setRecipients(
        data?.recipients?.map((res: any) => ({
          label: `${res.name} (+${res.phone})`,
          value: res.phone,
        }))
      );
    } catch (error) {
      toast.error("Failed to load recipients", { duration: 2000 });
    }
  };
  const getTemplates = async () => {
    const url = `${config.endpoints.template.getTemplates}`;
    try {
      const { data }: any = await http.get(url);
      setTemplates(data.templates);
    } catch (error) {
      toast.error("Failed to get templates", { duration: 2000 });
    }
  };
  const updateRecipients = (e: any) => {
    setSelectedRecipients(e && e.length > 0 ? e : []);
  };
  const checkParameters = async () => {
    const count = TemplateHelper.checkParamater(selectedTemplate);
    if (count.totalCount === 0 || isNaN(count.totalCount)) {
      const body = {
        recipients: selectedRecipients.map((rec: any) => rec.value),
        type: "template",
        typeBody: {
          name: selectedTemplate.name,
          language: {
            code: selectedTemplate.language,
          },
        },
        category: selectedTemplate.category,
      };
      await onSendMessage(body);
    } else {
      const cloneTemplate = selectedTemplate;
      setSelectedTemplate(cloneTemplate);
      setTemplateParameter(count);
      setCurrentStep(1);
    }
  };
  const onSendMessage = async (body: any) => {
    const url = `${config.endpoints.chat.sendBulkMessage}`;
    setLoader(true);
    try {
      await http.post(url, body);
      setCurrentStep(0);
      closeModal(false);
      setLoader(false);
      toast.success("Messages will be send in background", { duration: 2000 });
    } catch (error) {
      setLoader(false);
      toast.error("Failed to send message", { duration: 2000 });
    }
  };
  const goToStep = (newStep: number) => {
    setLoader(false);
    setSelectedTemplate(defaultTemplate);
    setTemplateParameter(defaultTemplateParams);
    setCurrentStep(newStep);
  };

  const onCloseModal = () => {
    dispatch(bulkMsgUsersAsync([]));
    closeModal(false);
  };

  return (
    <>
      <Modal size="w-8/12" show={show} onCloseModal={onCloseModal}>
        {currentStep === 0 && (
          <div>
            <h2 className="border-gray border-b p-3 text-lg font-semibold text-gray-600">
              Send bulk messages
            </h2>
            <div className="mt-4 h-80 overflow-y-auto">
              <div className="relative space-y-4">
                <div className="px-4">
                  <h5 className="text-brand">Recipients</h5>
                  <Select
                    className="mt-1 rounded-md bg-white text-sm"
                    classNamePrefix="wext"
                    isMulti
                    options={recipients}
                    onChange={updateRecipients}
                    value={selectedRecipients}
                    components={{ Input }}
                    placeholder="Select one or multiple recipients"
                  />
                </div>
                <div className="px-4">
                  <h5 className="text-brand">Select Templates</h5>
                  <RadioGroup
                    value={selectedTemplate}
                    onChange={setSelectedTemplate}
                  >
                    <RadioGroup.Label className="sr-only">
                      Templates
                    </RadioGroup.Label>
                    {templates.length > 0 &&
                      templates.map((d, key) => (
                        <RadioGroup.Option
                          key={key}
                          value={d}
                          className={({ active, checked }) =>
                            `${active
                              ? "ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-brand-50"
                              : ""
                            }
            ${checked
                              ? "rounded-md bg-brand-50 px-4 py-4 text-brand"
                              : "bg-white py-4"
                            }
              relative flex cursor-pointer focus:outline-none`
                          }
                        >
                          {({ active, checked }) => (
                            <>
                              <div className="flex w-full items-center justify-between">
                                <div className="flex items-center">
                                  <div className="text-sm">
                                    <RadioGroup.Label
                                      as="p"
                                      className={`mb-2 font-semibold uppercase  ${checked ? "text-brand" : "text-gray-900"}`}
                                    >
                                      {d.name}
                                    </RadioGroup.Label>
                                    <RadioGroup.Description
                                      as="div"
                                      className={`inline ${checked ? "text-brand" : "text-gray-500"}`}
                                    >
                                      <Preview data={d} />
                                    </RadioGroup.Description>
                                  </div>
                                </div>
                                {checked && (
                                  <div className="shrink-0 text-brand">
                                    <CheckCircleIcon className="h-6 w-6" />
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                  </RadioGroup>
                </div>
              </div>
            </div>
            {selectedRecipients &&
              selectedRecipients.length > 0 &&
              selectedTemplate &&
              selectedTemplate.id &&
              currentStep === 0 && (
                <div className="relative bottom-0 left-0 right-0 flex rounded-b-2xl bg-brand p-4">
                  <h5 className="text-sm font-semibold uppercase text-white">
                    {selectedTemplate.name}
                  </h5>
                  <div className="absolute bottom-3 right-3 z-10 flex p-4">
                    <button
                      type="button"
                      className="rounded-full bg-brand-600 p-3 shadow-xl shadow-brand-800 disabled:opacity-25"
                      onClick={checkParameters}
                    >
                      {loader ? (
                        <Spinner />
                      ) : (
                        <ArrowRightIcon className="h-6 w-6 text-white" />
                      )}
                    </button>
                  </div>
                </div>
              )}
          </div>
        )}
        {currentStep === 1 && (
          <Parameters
            data={templateParameter}
            template={selectedTemplate}
            loader={loader}
            onClickHandle={onSendMessage}
            onBack={goToStep}
          />
        )}
      </Modal>
    </>
  );
};
export default BulkChat;
