import { getWithExpiry, setWithExpiry } from "storage";

import { useEffect } from "react";

const ErrorFallback = ({ error }: { error: any; }) => {
  // Handle failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry("chunk_failed")) {
        setWithExpiry("chunk_failed", "true", 10000);
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="w-full max-w-2xl">
        <div className="font-mono flex flex-col items-center justify-center rounded-md bg-black p-4 shadow-md">
          <p className="text-white">Something went wrong.</p>
          <pre className="whitespace-pre-wrap font-semibold text-red-500">
            {error?.message}
          </pre>
        </div>
        <div className="flex items-center justify-center pt-4">
          <button
            className="font-sans text-lg font-semibold text-brand"
            onClick={() => window.location.reload()}
            type="button"
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};
export default ErrorFallback;
